<template>
	<div class="box">
		<template v-if="ready">
			<div class="row">
				<div class="col-6">
					<label class="col-form-label" for="date_mesure">{{ $t('croissance.date_mesure') }}</label>
					<e-datepicker
						id="date_mesure"
						v-model="date_mesure"
					/>
				</div>
				<div class="col-6">
					<label class="col-form-label" for="horse_nec">
						{{ $t('croissance.horse_nec') }}
					</label>
	                <input
					id="horse_nec"
					class="form-control"
					type="number"
					:placeholder="$t('croissance.horse_nec')"
					v-model="selected_nec"
					max="5"
					min="0"
					/>
					<b-form-checkbox id="nec-avance" v-model="nec_avance">{{ $t('croissance.nec_avance') }}</b-form-checkbox>
				</div>
			</div>
			<div v-if="nec_avance" class="row">
				<div class="col-4">
					<label class="col-form-label" for="horse_nec_garrot">
						{{ $t('croissance.horse_nec_garrot') }}
					</label>
					<input
					id="horse_nec_garrot"
					class="form-control"
					type="number"
					:placeholder="$t('croissance.horse_nec_garrot')"
					v-model="horse_nec_garrot"
					max="5"
					min="0"
					@input="calculateNec"
					/>
				</div>
				<div class="col-4">
					<label class="col-form-label" for="horse_nec_epaule">
						{{ $t('croissance.horse_nec_epaule') }}
					</label>
					<input
					id="horse_nec_epaule"
					class="form-control"
					type="number"
					:placeholder="$t('croissance.horse_nec_epaule')"
					v-model="horse_nec_epaule"
					max="5"
					min="0"
					@input="calculateNec"
					/>
				</div>
				<div class="col-4">
					<label class="col-form-label" for="horse_nec_cotes">
						{{ $t('croissance.horse_nec_cotes') }}
					</label>
					<input
					id="horse_nec_cotes"
					class="form-control"
					type="number"
					:placeholder="$t('croissance.horse_nec_cotes')"
					v-model="horse_nec_cotes"
					max="5"
					min="0"
					@input="calculateNec"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<label class="col-form-label" for="horse_perimetre">{{ $t('croissance.horse_perimetre') }}</label>	
	                <b-form-input
	                    id="horse_perimetre"
	                    class="form-control"
	                    type="number"
	                    :placeholder="$t('croissance.horse_perimetre')"
	                    v-model="horse_perimetre"
	                />
				</div>
				<div class="col-6">
					<label class="col-form-label" for="horse_garrot">{{ $t('croissance.horse_garrot') }}</label>	
	                <b-form-input
	                    id="horse_garrot"
	                    class="form-control"
	                    type="number"
	                    :placeholder="$t('croissance.horse_garrot')"
	                    v-model="horse_garrot"
	                />
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<label class="col-form-label" for="horse_poids_estimated">{{ $t('croissance.horse_poids_estimated') }}</label>	
	                <b-form-input
	                    id="horse_poids_estimated"
	                    class="form-control"
	                    type="number"
	                    :disabled="true"
	                    :placeholder="$t('croissance.horse_poids_estimated')"
	                    v-model="horse_poids_estimated"
	                />
				</div>
				<div class="col-6">
					<label class="col-form-label" for="horse_poids">{{ $t('croissance.horse_poids') }}</label>	
	                <b-form-input
	                    id="horse_poids"
	                    class="form-control"
	                    type="number"
	                    :placeholder="$t('croissance.horse_poids')"
	                    v-model="horse_poids"
	                />
				</div>
				<div v-if="horse.horse_sexe == 'F' && day >= 1460" class="col-6 ">
					<div class="custom-control custom-checkbox mt-4">
						<input type="checkbox" id="horse_pouliniere" class="custom-control-input" v-model="horse_pouliniere">
						<label class="custom-control-label ml-n2" for="horse_pouliniere"><h5>{{ $t('croissance.horse_pouliniere') }}</h5></label>
					</div>
				</div>
				<div class="col-12 mt-3 text-center">
					<b-button v-if="croissance_id" @click.prevent="removeCroissance" class="mr-2"><font-awesome-icon :icon="['fal', 'trash']" /> {{ $t("global.supprimer") }}</b-button>
					<b-button @click.prevent="saveCroissance" variant="primary"><font-awesome-icon v-if="!saving" :icon="['fal', 'save']" /><font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
				</div>
			</div>
			<div class="row">
				<div class="mx-auto">
					<img
						:src="require(`GroomyRoot/assets/img/schema_tailles_${lang}.png`)"
					/>
				</div>
			</div>
		</template>
		<LoadingSpinner v-else />
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Croissance from "@/mixins/Croissance.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: 'AddHorseCroissance',
		mixins: [Croissance, Horse],
		props: ['horse_id'],
		data () {
			return {
				horse: null,
				selected_nec: null,
				date_mesure: new Date(),
				horse_nec: null,
				horse_perimetre: null,
				horse_garrot: null,
				horse_poids_estimated: null,
				horse_poids: null,
				horse_pouliniere: null,
				croissance_id: null,
				ready: false,
				saving: false,
				day: null,
				nec_avance: false,
				horse_nec_garrot: null,
				horse_nec_epaule: null,
				horse_nec_cotes: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.reset()
				this.croissance_id = null
				this.horse = await this.getHorseById(this.horse_id)
				if(this.$route.params.croissance_id) {
					this.croissance_id = this.$route.params.croissance_id
					const croissance = await this.getCroissanceById(this.horse_id, this.croissance_id)
					this.date_mesure = new Date(croissance.croissance_date)
					this.horse_perimetre = croissance.croissance_perimetre
					this.horse_garrot = croissance.croissance_garrot
					this.selected_nec = croissance.croissance_nec
					this.horse_nec_garrot = croissance.croissance_nec_garrot
					this.horse_nec_epaule= croissance.croissance_nec_epaule 
					this.horse_nec_cotes = croissance.croissance_nec_cotes
					if(croissance.croissance_estimated === "0") {
						this.horse_poids = croissance.croissance_poids
					}
					if(this.horse_nec_garrot || this.horse_nec_epaule || this.horse_nec_cotes){
						this.nec_avance = true
					}
				}
				this.day = this.calculDifference(new Date(this.horse.horse_datenaissance), new Date(this.date_mesure))

				this.ready = true
			},

			async saveCroissance() {
				this.saving = true
				const params = {
					date_mesure: this.date_mesure,
					horse_perimetre: this.horse_perimetre,
					horse_garrot: this.horse_garrot,
					horse_poids_estimated: this.horse_poids_estimated,
					horse_poids: this.horse_poids,
					horse_nec: this.selected_nec,
					horse_nec_garrot: this.horse_nec_garrot,
					horse_nec_epaule: this.horse_nec_epaule,
					horse_nec_cotes: this.horse_nec_cotes
				}

                await this.$sync.force()
                const id = this.$sync.replaceWithReplicated('horse', this.horse_id)

				let alerts = []
				if(this.croissance_id) {
					alerts = await this.updateCroissance(id, this.croissance_id, params)
				}
				else {
					alerts = await this.addCroissance(id, params)
				}
				alerts.forEach((alerte) => {
					this.infoToast('toast.' + alerte)
				})
				this.reset()
				
				this.saving = false
				this.$router.push({ name: 'HorseSuiviCroissance', params: { horse_id: this.horse_id }})
			},

			async removeCroissance() {
				await this.deleteCroissance(this.horse_id, this.croissance_id)
				this.$router.push({ name: 'HorseSuiviCroissance', params: { horse_id: this.horse_id }})
			},

			calculPoidsEstimated() {
				if(!this.horse) {
					return false
				}
				// https://equipedia.ifce.fr/elevage-et-entretien/alimentation/nutrition-et-ration/estimation-du-poids
				this.day = this.calculDifference(new Date(this.horse.horse_datenaissance), new Date(this.date_mesure))

				if(!this.date_mesure || !this.horse_perimetre || !this.horse) {
					this.horse_poids_estimated = null
					return false
				}
	
				let horse_poids_estimated = 0;

				if(!this.horse.horse_datenaissance) {
					horse_poids_estimated = 4.5 * this.horse_perimetre
					this.horse_poids_estimated = horse_poids_estimated.toFixed(2)
					return true
				}

				if (this.day >= 1460){
					//poney 
					if(this.horse.race_type.includes("4")){
						horse_poids_estimated = 3.56 * this.horse_garrot + 3.65 * this.horse_perimetre - 714.66
					}
					//chevaux de trait
					else if (this.horse.race_type.includes("5")){
						horse_poids_estimated = 7.3 * this.horse_perimetre - 800
					}
					//pouliniere de sport
					else if (!this.horse.race_type.includes("4") && !this.horse.race_type.includes("5") && !this.horse.race_type.includes("6") && this.horse_pouliniere == true){
						horse_poids_estimated = 2.6 * this.horse_garrot + 5.2 * this.horse_perimetre - 855
					}
					//chevaux de sport
					else {
						horse_poids_estimated = 3 * this.horse_garrot + 4.3 * this.horse_perimetre - 785
					}
				} else if (this.day >= 730){
					horse_poids_estimated = 4.5 * this.horse_perimetre - 370
				} else {
					if(this.horse.horse_sexe == "F" && this.horse_garrot) {
					horse_poids_estimated = (0.21 * this.day) + (1.85 * this.horse_garrot) + (2.01 * this.horse_perimetre) - 330.2
					}
					else if((this.horse.horse_sexe == "M" || this.horse.horse_sexe == "H") && this.horse_garrot) {
						horse_poids_estimated = (0.20 * this.day) + (1.72 * this.horse_garrot) + (2.15 * this.horse_perimetre) - 327
					}
					else {
						horse_poids_estimated = 4.5 * this.horse_perimetre - 370
					}
				}
				
				this.horse_poids_estimated = horse_poids_estimated.toFixed(2)
			},

			calculDifference(date1, date2) {
                date1.setHours(0,0,0,0);
                date2.setHours(0,0,0,0);
                let Difference_In_Time = date2.getTime() - date1.getTime(); 
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
                return Math.round(Difference_In_Days);
            },

			calculateNec(){
				this.selected_nec = ((this.horse_nec_garrot * 15) + (this.horse_nec_epaule * 25) + (this.horse_nec_cotes * 60)) / 100
			},

            reset() {
				this.date_mesure = new Date()
				this.horse_perimetre = null
				this.horse_garrot = null
				this.horse_poids_estimated = null
				this.horse_poids = null
            }
		},

		watch: {
			horse_pouliniere() {
				this.calculPoidsEstimated()
			},
			date_mesure() {
				this.calculPoidsEstimated()
			},
			horse_perimetre(val) {
				if(val)
					this.calculPoidsEstimated()
			},
			horse_garrot(val) {
				if(val)
					this.calculPoidsEstimated()
			},
			horse_poids(val) {
				if(val)
					this.calculPoidsEstimated()
			},
			'$route.params.croissance_id'() {
				this.init_component()
			},
			selected_nec(val) {
				if(val > 5){
					this.selected_nec = 5
				}
				if(val < 0){
					this.selected_nec = 0
				}
				this.selected_nec= Math.floor(this.selected_nec * 100) / 100
				
			}
		},

		computed: {
			lang() {
				return Vue.i18n.locale()
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>